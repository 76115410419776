import React, { createContext, useReducer, useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import useDidMountEffect from "../hooks/didMount"

const COOKIE_NAME = "consent"

const CookieConsentContext = createContext({
  isSet: false,
  performance: false,
  marketing: false,
})

const CookieConsentProvider = ({ children }) => {
  const [cookie, setCookie] = useCookies([COOKIE_NAME])

  let initialCookieValue = cookie[COOKIE_NAME] || {
    isSet: false,
    performance: false,
    //marketing: false,
  }

  const [popupIsOpen, setPopupIsOpen] = useState(false)

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "performance":
        return {
          ...state,
          performance: !state.performance,
        }
      case "marketing":
        return {
          ...state,
          marketing: !state.marketing,
        }
      case "acceptCurrent":
        setPopupIsOpen(false)
        return {
          ...state,
          isSet: true,
        }
      case "declineAll":
        setPopupIsOpen(false)
        return {
          isSet: true,
          performance: false,
          marketing: false,
        }
      case "acceptAll":
        setPopupIsOpen(false)
        return {
          isSet: true,
          performance: true,
          marketing: true,
        }
      case "showCookiePopup":
        return state
      default:
        throw new Error()
    }
  }, initialCookieValue)

  useEffect(() => {
    setPopupIsOpen(!state.isSet)
  }, [])

  useEffect(() => {
    state.isSet &&
      window.dataLayer.push({
        consent_performance: state.performance,
        //consent_marketing: state.marketing,
        event: "gatsby-route-change",
      })
  }, [state])

  useDidMountEffect(() => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)

    state.isSet &&
      setCookie(COOKIE_NAME, state, {
        path: "/",
        expires: date,
        domain: `${process.env.GATSBY_COOKIE_DOMAIN}`,
        secure: false,
        httpOnly: false,
        sameSite: "lax",
      })
  }, [state])

  return (
    <CookieConsentContext.Provider
      value={{
        state,
        dispatch,
        popupIsOpen,
        setPopupIsOpen,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  )
}

export default CookieConsentContext
export { CookieConsentProvider }
