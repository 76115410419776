import React from "react"
import { CookieConsentProvider } from "./src/context/CookieContext"
import { Cookies } from "react-cookie"

export const wrapRootElement = ({ element }) => (
  <CookieConsentProvider>{element}</CookieConsentProvider>
)

export const onRouteUpdate = (_, pluginOptions) => {
  const cookies = new Cookies()

  const { performance, marketing } = cookies.get("consent") || {
    isSet: false,
    performance: false,
    marketing: false,
  }
  window.dataLayer.push({
    consent_performance: performance,
    consent_marketing: marketing,
  })
}
